.flex-bar {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.title-bar {
  position: relative;
  padding-block-end: 32px;
  padding-inline-start: 32px;
  padding-inline-end: 32px;

  &::after {
    border-block-end: 1px solid var(--color-blue-300);
    width: calc(100% + 64px);
    transform: translateX(-32px);
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
  }
}

.title-bar-success h1 {
  color: var(--color-success);
}

.data-no-data {
  padding: 32px;
}

.button-wrapper {
  width: 100%;
}

.dot-nav {
  width: 100%;
  height: fit-content;
  position: relative;
  overflow: hidden;
}

.dot-nav-confirm {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  transform: translateY(-100%);
  transition: transform 0.15s linear;

  & button {
    max-height: 100%;
  }

  &.active {
    transform: translateY(0);
  }
}

.dot-nav-success {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 3;
  transform: translateY(-100%);
  transition: transform 0.15s linear;
  background-color: var(--color-success);

  & p {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-white-100);
  }

  display: grid;
  place-items: center;

  & span {
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    background-color: rgb(0 0 0 / 20%);
  }

  &.active {
    transform: translateY(0);

    & span {
      animation: fill 2s 1 linear forwards;
    }
  }
}

.dot-nav-failure {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 4;
  transform: translateY(-100%);
  transition: transform 0.15s linear;
  background-color: var(--color-error);

  & p {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-white-100);
  }

  display: grid;
  place-items: center;

  /* stylelint-disable */
  & span {
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    background-color: rgb(0 0 0 / 20%);
  }
  /* stylelint-enable */

  &.active {
    transform: translateY(0);

    & span {
      animation: fill 2s 1 linear forwards;
    }
  }
}

@keyframes fill {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.icon {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: var(--color-success);
  display: grid;
  place-items: center;
}

.flex-row {
  display: flex;
  gap: 16px;
  flex-flow: row nowrap;
  align-items: center;
}

.question-element {
  width: 100%;
  padding: 32px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  gap: 32px;
  align-items: center;
}

.answer-element {
  width: 100%;
  padding-inline-start: 32px;
  padding-inline-end: 32px;
  padding-block-end: 64px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  gap: 32px;
  align-items: center;
}

.debug-console {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 2px;
  background-color: var(--color-error);
  color: var(--color-white-100);
}

.loading {
  width: 100%;
  height: 100%;
  min-height: 200px;
  display: grid;
  place-items: center;
}

.introduction-text {
  margin-block-end: 24px;
}
