.button {
  position: relative;
  text-decoration: none;
  display: inline-flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid var(--color-orange-900);
  background-color: var(--color-orange-900);
  color: var(--color-white-100);
  margin: 0;
  border-radius: 20px;
  transition: all 0.2s linear;
  width: auto;
  padding-inline-start: 16px;
  padding-inline-end: 16px;
  padding-block-start: 12px;
  padding-block-end: 12px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  white-space: nowrap;

  &:not(:disabled) {
    &:hover {
      border: 0.1rem solid #545454;
      background-color: #545454;
    }
  }

  /* stylelint-disable */
  &:disabled,
  &:disabled:hover {
    border: 0.1rem solid var(--color-blue-700);
    background-color: var(--color-blue-700);
    color: var(--color-white-100);
    cursor: not-allowed;
  }
  /* stylelint-enable */

  @media (width >= 768px) {
    width: auto;
  }
}

.button-primary {
  &:not(:disabled) {
    background-color: var(--color-orange-900);
    border: 0;
  }

  /* stylelint-disable */
  &:hover {
    &:not(:disabled) {
      background-color: var(--color-orange-300);
      color: var(--color-blue-900);
     border: 0;
   }
  }

  &:active{
    &:not(:disabled) {
      background-color: var(--color-orange-100);
      color: var(--color-blue-900);
      border: 0;
    }
  }

  &.button-primary-purple{
    &:not(:disabled) {
      background-color: var(--color-purple-900);
      border: 0;
    }

    &:hover {
      &:not(:disabled) {
        background-color: var(--color-purple-300);
        color: var(--color-blue-900);
      border: 0;
    }
    }

    &:active{
      &:not(:disabled) {
        background-color: var(--color-purple-100);
        color: var(--color-blue-900);
        border: 0;
      }
    }
  }

  &:disabled,
  &:disabled:hover {
    border: 0.1rem solid var(--color-grey-100);
    background-color: var(--color-white-100);
    color: var(--color-grey-300);
    cursor: not-allowed;
  }
  /* stylelint-enable */
}

.button-secondary {
  &:not(:disabled) {
    font-weight: 400;
    background-color: transparent;
    color: var(--color-orange-900);
    border: 0;
  }

  /* stylelint-disable */
  &:hover {
    &:not(:disabled) {
      background-color: var(--color-orange-100);
      color: var(--color-orange-900);
     border: 0;
   }
  }

  &:active{
    &:not(:disabled) {
      background-color: transparent;
      color: var(--color-orange-900);
      border: 0;
    }
  }

  &.button-secondary-purple{
     &:not(:disabled) {
      background-color: transparent;
      color: var(--color-purple-900);
    }

    &:hover {
      &:not(:disabled) {
        background-color: var(--color-purple-100);
        color: var(--color-purple-900);
      }
    }

    &:active{
      &:not(:disabled) {
        background-color: var(--color-purple-100);
        color: var(--color-purple-900);
      }
    }
  }

  &:disabled,
  &:disabled:hover {
    border: 0.1rem solid var(--color-grey-100);
    background-color: var(--color-white-100);
    color: var(--color-grey-300);
    cursor: not-allowed;
  }
  /* stylelint-enable */
}

.button-bordered {
  &:not(:disabled) {
    background-color: transparent;
    border: 1px solid var(--color-orange-900);
    color: var(--color-orange-900);
  }

  /* stylelint-disable */
  &:disabled,
  &:disabled:hover {
    border: 0.1rem solid var(--color-blue-700);
    background-color: transparent;
    color: var(--color-blue-700);
    cursor: not-allowed;
  }

  &:hover {
    &:not(:disabled) {
      background-color: var(--color-orange-100);
      border: 1px solid var(--color-orange-900);
      color: var(--color-orange-900);
    }
  }

  &:active{
    &:not(:disabled) {
      background-color: transparent;
      border: 1px solid var(--color-orange-900);
      color: var(--color-orange-900);
    }
  }

  &.button-bordered-purple{
    &:not(:disabled) {
      background-color: transparent;
      border: 1px solid var(--color-purple-900);
      color: var(--color-purple-900);
    }

    &:disabled,
    &:disabled:hover {
      border: 0.1rem solid var(--color-blue-700);
      background-color: transparent;
      color: var(--color-blue-700);
      cursor: not-allowed;
    }

    &:hover {
      &:not(:disabled) {
        background-color: var(--color-purple-100);
        border: 1px solid var(--color-purple-900);
        color: var(--color-purple-900);
      }
    }

    &:active{
      &:not(:disabled) {
        background-color: transparent;
        border: 1px solid var(--color-purple-900);
        color: var(--color-purple-900);
      }
    }

    &.selected{
      &:not(:disabled) {
        background-color: var(--color-purple-900);
        border: 1px solid var(--color-purple-900);
        color: var(--color-white-100);
      }

      &:disabled,
      &:disabled:hover {
        border: 0.1rem solid var(--color-blue-700);
        background-color: var(--color-blue-700);
        color: var(--color-white-100);
        cursor: not-allowed;
      }
      
      &:active{
        &:not(:disabled) {
          background-color: var(--color-purple-900);
          border: 1px solid var(--color-purple-900);
          color: var(--color-white-100);
        }
      }
    }
  }

  &:disabled,
  &:disabled:hover {
    border: 0.1rem solid var(--color-grey-100);
    background-color: var(--color-white-100);
    color: var(--color-grey-300);
    cursor: not-allowed;
  }

  &:disabled,
  &:disabled:hover {
    border: 0.1rem solid var(--color-grey-100);
    background-color: var(--color-white-100);
    color: var(--color-grey-300);
    cursor: not-allowed;
  }
  /* stylelint-enable */
}

.button-link {
  font-weight: 400;
  padding: 0;
  border: 0;

  &:not(:disabled) {
    background-color: transparent;
    color: var(--color-orange-900);
  }

  &:hover {
    &:not(:disabled) {
      background-color: transparent;
      text-decoration: underline;
      border: 0;
      color: var(--color-orange-900);
    }
  }

  /* stylelint-disable */
  &:active {
    &:not(:disabled) {
      background-color: transparent;
      border: 0;
      text-decoration: underline;
      color: var(--color-blue-900);
    }
  }

  &:disabled,
  &:disabled:hover {
    border: 0;
    background-color: var(--color-white-100);
    color: var(--color-grey-300);
    cursor: not-allowed;
  }
  /* stylelint-enable */
}

.audio-indicator {
  position: absolute;
  left: 4px;
  top: 4px;
  height: calc(100% - 8px);
  padding: 4px 12px;
  background-color: var(--color-orange-900);
  display: grid;
  place-items: center;
  border-radius: 16px;

  & svg {
    height: 75%;
  }
}
