.character {
  font-style: normal;
  font-weight: 700;
  font-size: 120px;
  line-height: 120px;
  display: flex;
  align-items: center;
  color: var(--color-blue-900);
  margin-block-end: 0;
}

.cue-audio-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.audio-wrapper {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
