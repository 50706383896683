.error-wrapper {
  background-color: var(--color-error);
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  color: var(--color-white-100);
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
}

.button {
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  appearance: none;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-inline-start: 14px;
  padding-inline-end: 14px;
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  color: var(--color-white-100);

  &:hover {
    color: var(--color-orange-100);
  }

  &:active {
    color: var(--color-orange-300);
  }
}
