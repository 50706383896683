.button {
  background: transparent;
  border: 0;
  outline: 0;
  box-shadow: none;
  text-decoration: none;
  display: inline-flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--color-blue-900);
  color: var(--color-white-100);
  margin: 0;
  transition: all 0.2s linear;
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  border-radius: 0 0 8px 8px;
  height: 85px;

  &:not(:disabled) {
    &:hover {
      background-color: var(--color-blue-800);
    }

    &:active {
      background-color: var(--color-blue-950);
    }
  }

  /* stylelint-disable */
  &:disabled,
  &:disabled:hover {
    border: 0.1rem solid var(--color-grey-300);
    background-color: var(--color-grey-300);
    color: var(--color-white-100);
    cursor: not-allowed;
  }
  /* stylelint-enable */
}
