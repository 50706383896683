@font-face {
  font-family: Helvetica;
  src: url("./fonts/Helvetica-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Helvetica;
  src: url("./fonts/Helvetica-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Helvetica;
  src: url("./fonts/Helvetica-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: IndieFlower;
  src: url("./fonts/IndieFlower-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: var(--color-blue-900);
}

h2 {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: var(--color-blue-900);
}

h3 {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}

h4 {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

p {
  color: var(--color-blue-900);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  padding: 0;

  &:not(:last-of-type) {
    margin-block-end: 24px;
  }
}

ul {
  & li {
    list-style-type: "- ";
    color: var(--color-blue-900);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    padding: 0;
  }
}

.error-text {
  color: var(--color-error);
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.helper-text {
  font-weight: 400;
  color: var(--color-blue-700);
  font-size: 12px;
  line-height: 14px;
  display: inline-block;
  margin: 16px 0 0;
}
