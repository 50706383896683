.word {
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 51px;
  display: flex;
  align-items: center;
  color: var(--color-blue-900);
  margin-block-end: 0;

  &.active {
    background-color: var(--color-orange-900);
  }
}

.word-divider {
  background-color: var(--color-purple-900);
  display: inline-block;
  height: 12px;
  width: 12px;
  border-radius: 6px;
}

.synonym {
  background-color: var(--color-orange-500);
  color: var(--color-white-100);
  padding: 4px 16px;
  border-radius: 20px;
  margin-block-start: 8px;
  display: inline-block;
  width: auto;
  pointer-events: none;
  user-select: none;
  margin-inline-end: 8px;

  &.active {
    background-color: var(--color-orange-900);
  }
}

.grapheme {
  background-color: var(--color-orange-500);
  color: var(--color-white-100);
  padding: 4px 24px;
  border-radius: 20px;
  margin-block-start: 8px;
  display: inline-block;
  width: auto;
  pointer-events: none;
  user-select: none;
}
