@import url("./typography.css");

:root {
  /* ORANGE */
  --color-orange-100: #ffeceb;
  --color-orange-300: #ffbeb9;
  --color-orange-500: #ff918c;
  --color-orange-900: #ff7670;

  /* PURPLE */
  --color-purple-100: #f3edef;
  --color-purple-300: #f3e3e8;
  --color-purple-500: #dcbec9;
  --color-purple-900: #8e4f66;

  /* BLUE */
  --color-blue-200: #f5f2f2;
  --color-blue-300: #ebe6e6;
  --color-blue-500: #d9d9d9;
  --color-blue-700: #a0aaaf;
  --color-blue-800: #69737d;
  --color-blue-900: #41505f;
  --color-blue-950: #233241;

  /* WHITE */
  --color-white-100: #fff;

  /* SIGNAL */
  --color-info: #5a9fb9;
  --color-error: #da5858;
  --color-success: #41aa9b;
  --color-warning: #e1b95f;

  /* PRIMARY */
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 16px;
  font-family: Helvetica, Arial, sans-serif;
  background-color: var(--color-blue-300);
  overflow-x: hidden;
}

html,
body {
  padding: 0;
  margin: 0;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: rotate 350ms linear infinite;
  width: 16px;
  height: 16px;
  transform-origin: center center;
}
