.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
  display: grid;
  place-items: center;

  & > * {
    grid-row: 1;
    grid-column: 1;
  }
}

.background {
  height: 100%;
  width: 100%;
  background-color: rgb(40 54 68 / 90%);
}

.card {
  background-color: var(--color-white-100);
  box-shadow: 0 10px 25px rgb(65 80 95 / 15%);
  border-radius: 8px;
  max-width: 100%;
  max-height: 100%;

  &.card-medium {
    width: 752px;
    padding: 32px;
    display: flex;
    flex-flow: column nowrap;
    gap: 0;
    justify-content: flex-start;
    align-items: stretch;
  }
}
