.answer-flexbox {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  gap: 32px;
  align-items: center;

  & > * {
    flex: 1;
  }
}
