.audio-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-blue-900);
  border: 1px transparent;
  border-radius: 50%;

  &:hover {
    cursor: pointer;
    background: var(--color-blue-800);
  }

  &:disabled {
    background: var(--color-blue-700);
  }

  &.hint {
    background: var(--color-orange-900);

    &:hover {
      background: var(--color-orange-300);
    }
  }

  & svg {
    height: 50%;
  }
}

.btn-small {
  height: 44px;
  width: 44px;
}

.btn-medium {
  height: 52px;
  width: 52px;
}

.btn-large {
  height: 72px;
  width: 72px;
}

.spinner svg {
  height: unset;
}
