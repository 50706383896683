.dot-navigation {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  gap: 32px;
  align-items: center;
  width: 100%;
  height: fit-content;
  padding-inline-start: 32px;
  padding-inline-end: 32px;
  padding-block-start: 32px;
  padding-block-end: 32px;
  background: var(--color-blue-200);
  border-radius: 0 0 8px 8px;

  &.dot-navigation-spread {
    justify-content: space-between;
    gap: unset;
  }

  & .bullet {
    display: flex;
    height: 12px;
    width: 12px;
    flex: 0 0 12px;
    border-radius: 50%;
    background: var(--color-blue-700);
  }

  & .bullet-active {
    background: var(--color-blue-900);
  }

  & .bullet-done {
    background: var(--color-success);
  }
}
