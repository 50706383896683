.image-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  margin-block-start: 32px;

  &.image-grid-large {
    grid-template-columns: repeat(3, 1fr);
  }
}

.answer-image {
  background: none;
  box-shadow: none;
  outline: none;
  height: 141px;
  border: 2px solid var(--color-blue-700);
  border-radius: 16px;
  padding: 8px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &.answer-image-large {
    height: 222px;
  }

  & img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center center;
  }

  &:disabled {
    background-color: var(--color-blue-200);
    border: 0;
    padding: 10px;
    cursor: not-allowed;

    & img {
      mix-blend-mode: multiply;
    }
  }

  &:not(:disabled) {
    &:hover {
      background-color: var(--color-purple-300);
      border: 4px solid var(--color-purple-900);
      padding: 6px;

      & img {
        mix-blend-mode: multiply;
      }
    }

    &:active {
      background-color: var(--color-purple-300);
      border: 4px solid var(--color-purple-900);
      padding: 6px;

      & img {
        mix-blend-mode: multiply;
      }
    }
  }

  &.active {
    background-color: var(--color-purple-300);
    border: 4px solid var(--color-purple-900);
    padding: 6px;

    /* stylelint-disable */
    & img {
      mix-blend-mode: multiply;
    }
    /* stylelint-enable */

    &:hover {
      background-color: var(--color-purple-300);
      border: 4px solid var(--color-purple-900);
      padding: 6px;

      & img {
        mix-blend-mode: multiply;
      }
    }
  }
}
