.answer-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;

  &.answer-grid-large {
    grid-template-columns: repeat(3, 1fr);
  }
}
