.synonym {
  background-color: var(--color-orange-500);
  color: var(--color-white-100);
  padding: 4px 16px;
  border-radius: 20px;
  margin-block-start: 8px;
  display: inline-block;
  width: auto;
  pointer-events: none;
  user-select: none;
  margin-inline-end: 8px;

  &.active {
    background-color: var(--color-orange-900);
  }
}

.grapheme {
  background-color: var(--color-orange-500);
  color: var(--color-white-100);
  padding: 4px 24px;
  border-radius: 20px;
  margin-block-start: 8px;
  display: inline-block;
  width: auto;
  pointer-events: none;
  user-select: none;
}

.image {
  height: 176px;
  width: 100%;
  object-fit: contain;
  object-position: center center;
}

.cue-audio-wrapper {
  position: relative;
  width: 100%;
}

.audio-wrapper {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.flex-col {
  display: flex;
  gap: 0;
  width: 100%;
  flex-flow: column nowrap;
  align-items: center;

  &.grapheme-audio {
    align-items: flex-start;
  }
}
