.page-wrapper {
  width: 100vw;
  min-height: calc(var(--app-height) - 48px);
  margin-block-start: 48px;
  position: relative;
  display: flex;
  gap: 0;
  flex-flow: column nowrap;
  justify-content: stretch;
  align-items: flex-start;

  & > * {
    width: 100%;
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  height: 48px;
  background-color: var(--color-blue-900);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.error-wrapper {
  height: 45px;
  position: fixed;
  left: 0;
  top: 48px;
  z-index: 99;
}

.main-wrapper {
  min-height: calc(var(--app-height) - 48px);
  padding-block-start: 80px;
  padding-block-end: 80px;

  &.main-wrapper-with-error {
    min-height: calc(var(--app-height) - 48px - 45px);
  }

  &:not(.main-wrapper-center-content) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;
  }

  &.main-wrapper-center-content {
    display: grid;
    place-items: center;
  }
}

.card {
  max-width: calc(100vw - 144px);

  /* height: calc(var(--app-height) - 208px); */
  display: grid;

  & .cues-layout {
    grid-row: 1;
    grid-column: 1;
    position: relative;
  }

  & .cues-container {
    z-index: 1;
    width: 72px;
    max-height: calc(var(--app-height) - 136px);
    position: absolute;
    top: 24px;
    right: 0;
    background-color: var(--color-blue-700);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 16px 0 32px;
    text-align: center;

    & p {
      color: var(--color-white-100);
      text-align: center;
      margin-block-end: 16px;
    }

    & ul {
      padding: 0;
      margin: 0;
      display: inline-block;
      list-style: none;

      & li {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-block-end: 8px;

        & button {
          border: 0;
          box-shadow: none;
          outline: 0;
          color: var(--color-white-100);
          text-align: center;
          height: 44px;
          width: 44px;
          border-radius: 22px;
          background-color: var(--color-blue-800);
          display: grid;
          place-items: center;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 28px;
          cursor: pointer;

          &.active {
            background-color: var(--color-orange-500);
            pointer-events: none;
          }

          &:hover {
            background-color: var(--color-blue-900);
          }

          &:active {
            background-color: var(--color-blue-950);
          }

          &.skip {
            background-color: var(--color-blue-900);

            &:hover {
              background-color: var(--color-blue-800);
            }

            &:active {
              background-color: var(--color-blue-950);
            }

            &:disabled {
              cursor: not-allowed;
              background-color: var(--color-blue-500);
            }

            & svg {
              transform: translateX(1px);
              width: 66%;
            }
          }
        }
      }
    }
  }

  & .card-paper {
    z-index: 2;
    grid-row: 1;
    grid-column: 1;
    width: 100%;
    height: 100%;
    background-color: var(--color-white-100);
    box-shadow: 0 10px 25px rgb(65 80 95 / 15%);
    border-radius: 8px;
  }

  & .content {
    border-radius: 8px;
    position: relative;
    z-index: 3;
    width: 100%;
    grid-row: 1;
    grid-column: 1;
    overflow: hidden;
    max-width: 100%;
    padding: 32px;
  }

  &.card-no-pb {
    & .content {
      padding-block-end: 0;
    }
  }

  &.card-no-pb-ph {
    & .content {
      padding-block-end: 0;
      padding-inline-start: 0;
      padding-inline-end: 0;
    }
  }

  &.card-small {
    width: 380px;
    max-width: calc(100vw - 144px);

    & .content {
      display: flex;
      flex-flow: column nowrap;
      gap: 40px;
      justify-content: flex-start;
      align-items: stretch;
    }
  }

  &.card-medium {
    & .content {
      max-width: calc(100vw - 144px);
      width: 752px;
      display: flex;
      flex-flow: column nowrap;
      gap: 0;
      justify-content: flex-start;
      align-items: stretch;
    }
  }

  &.card-large {
    & .content {
      max-width: calc(100vw - 144px);
      width: 1090px;
    }
  }
}

.nav-button {
  appearance: none;
  background: none;
  box-shadow: none;
  border: 0;
  cursor: pointer;
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: var(--color-white-100);
  height: 100%;
  padding-inline-start: 12px;
  padding-inline-end: 12px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  &:not(:disabled) {
    &:hover {
      background-color: var(--color-blue-800);
    }

    &:active {
      background-color: var(--color-blue-950);
    }
  }
}

.user {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: var(--color-white-100);
  height: 100%;
  padding-inline-start: 12px;
  padding-inline-end: 16px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.modal-content {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;
  gap: 32px;

  & > div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    gap: 32px;
  }
}

.debug-console {
  background: yellow;
  position: fixed;
  left: 0;
  bottom: 0;
}

.dot-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 8px;
}

.dot {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: var(--color-white-100);
}
