.audio-element {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.audio-label {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: var(--color-blue-700);
}

.synonym {
  background-color: var(--color-orange-500);
  color: var(--color-white-100);
  padding: 4px 16px;
  border-radius: 20px;
  margin-block-start: 8px;
  display: inline-block;
  width: auto;
  pointer-events: none;
  user-select: none;
  margin-inline-end: 8px;

  &.active {
    background-color: var(--color-orange-900);
  }
}

.grapheme {
  background-color: var(--color-orange-500);
  color: var(--color-white-100);
  padding: 4px 24px;
  border-radius: 20px;
  margin-block-start: 8px;
  display: inline-block;
  width: auto;
  pointer-events: none;
  user-select: none;
}

.flex-col {
  display: flex;
  gap: 0;
  width: 100%;
  flex-flow: column nowrap;
  align-items: center;

  &.grapheme-audio {
    align-items: flex-start;
  }
}
