.flex-bar {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.check-bar {
  justify-content: center;
}

.title-bar {
  position: relative;
  padding-block-end: 32px;
  padding-inline-start: 32px;
  padding-inline-end: 32px;

  &::after {
    border-block-end: 1px solid var(--color-blue-300);
    width: calc(100% + 64px);
    transform: translateX(-32px);
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
  }

  & button {
    position: absolute;
    right: 32px;
  }
}

.assignment {
  padding-block-start: 16px;
  padding-block-end: 16px;
  padding-inline-start: 32px;
  padding-inline-end: 32px;
}

.data-new {
  padding-block-start: 16px;
  padding-block-end: 16px;

  & p {
    color: var(--color-orange-900);
  }
}

.data-no-data {
  padding: 32px;
}

.data-done {
  & .assignment {
    position: relative;

    & h2,
    & p {
      color: var(--color-success);
      opacity: 0.5;
    }

    &:last-child {
      padding-block-end: 0;
    }

    &::after {
      border-block-end: 1px solid var(--color-blue-300);
      width: calc(100% + 64px);
      transform: translateX(-32px);
      content: "";
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.icon {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: var(--color-success);
  display: grid;
  place-items: center;
}

.data {
  padding-block-end: 32px;
}

.dot-nav {
  width: calc(100% + 64px);
  height: fit-content;
  transform: translate(-32px, 32px);
}

.loading {
  width: 100%;
  height: 100%;
  min-height: 200px;
  display: grid;
  place-items: center;
}
