.input {
  border: 1px solid var(--color-blue-300);
  padding: 12px 16px;
  background: var(--color-blue-300);
  border-radius: 80px;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: var(--color-blue-900);
  outline: none;
  width: 100%;

  &::placeholder {
    color: var(--color-blue-700);
  }

  &:focus {
    border: 1px solid var(--color-blue-900);
  }

  &.input-has-error {
    border: 1px solid var(--color-error);
    color: var(--color-error);
  }
}

.input-wrapper {
  position: relative;
  width: 100%;
}

.icon-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  z-index: 99;
  aspect-ratio: 1 / 1;

  & button {
    appearance: none;
    cursor: pointer;
    box-shadow: none;
    border: 0;
    background: none;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
  }
}
